import React from 'react';
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px #888 solid;
  th, td {
    border: 1px #888 solid;
    padding: 3px 8px;
    vertical-align: top;
  }
  margin-bottom: 10px;
`;

const SignupsCell = styled.td`
  padding: 0 !important;
`;

const SignupContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 3px 8px;
`;

const Caption = styled.caption`
  text-align:left;
  font-size: 1.1em;
  line-height: 1.1em;
  margin: 0 0 5px 0;
`;

const SignupName = styled.div`
  font-weight: bold;
  margin-right: 20px;
`;

const SignupEmail = styled.div`
  font-style: italic;
`;

const Badge = styled.div`
  color: #fff;
  padding: 2px 3px;
  font-size: 0.7em;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 10px;
  cursor: pointer;
`;

const PendingBadge = styled(Badge)`
  background-color: #e77817;
  text-shadow: 1px 1px 0 #72523f;
`;

const ExtraBadge = styled(Badge)`
  background-color: #7044a3;
  text-shadow: 1px 1px 0 #402C56;
`;

const BadgesContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 0;
  top: 0;
  border-bottom-left-radius: 2px;
  overflow: hidden;
`;

const ExtraFieldValue = styled.span`
  color: #888;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const Title = styled.h1`
  flex-grow: 1;
`;

const OpenCell = styled.td`
  text-align: center;
  width: 20px;
`;

const ItemCell = styled.td`
`;

const CaptionCell = styled.td`
  font-weight: bold;
  background-color: #ddd;
`;

const Signup = ({signup: {pending, isExtra, extraFields, name, email}}) => {
  const extraFieldsWithValue = extraFields.filter(({value}) => value);
  return (
    <SignupsCell>
      <SignupContainer>
        <BadgesContainer>
          {pending && <PendingBadge title="Pending signup">P</PendingBadge>}
          {isExtra && <ExtraBadge title="Extra signup">E</ExtraBadge>}
        </BadgesContainer>
        <SignupName>{name}</SignupName>
        <SignupEmail>{email}</SignupEmail>
        {extraFieldsWithValue.map(({id, name, value}) =>
          <div key={id}>{name}: <ExtraFieldValue>{value}</ExtraFieldValue></div>
        )}
      </SignupContainer>
    </SignupsCell>
  );
};

const Item = ({item: {name, atleast, allowExtra, signups}, display}) => {
  const signupsCount = signups.length;
  const difference = atleast - signupsCount;
  const open = Math.max(0, difference);
  const [firstSignup, ...moreSignups] = signups;
  const rowSpan = Math.max(1, signupsCount);
  return (
    <>
      <tr>
        <ItemCell rowSpan={rowSpan}>
          {[display, name].filter(Boolean).join(' | ')}
        </ItemCell>
        <OpenCell rowSpan={rowSpan}>
          {open}{allowExtra ? '+' : ''}
        </OpenCell>
        {firstSignup ? <Signup signup={firstSignup} /> : <td></td>}
      </tr>
      {moreSignups.map(signup => <tr key={signup.id}><Signup signup={signup} /></tr>)}
    </>
  );
};

const TimeSlot = ({timeSlot: {display, items}}) => {
  return (
    <>
      {items.map(item => <Item key={item.id} display={display} item={item} />)}
    </>
  )
};

const Section = ({caption, timeSlots, items}) => {
  return (
    <>
      <tr>
        <CaptionCell colSpan={3}>{caption}</CaptionCell>
      </tr>
      {timeSlots.map(timeSlot => <TimeSlot key={timeSlot.id} timeSlot={timeSlot} />)}
      {items.map(item => <Item key={item.id} item={item} />)}
    </>
  )
}

const Signups = ({sheet}) => {
  return (
    <>
      <TitleContainer>
        <Title>{sheet.title}</Title>
        <div><a href={`/sheets/signups_export?id=${sheet.id}&auth=${sheet.calendarAuthToken}`}>Export To CSV</a></div>
      </TitleContainer>
      <h2>Signups</h2>
      <Table>
        <thead>
          <tr>
            <th>Item</th>
            <th>Open</th>
            <th>Signups</th>
          </tr>
        </thead>
        <tbody>
          {(sheet.items.length > 0 || sheet.timeSlots.length > 0) &&
            <Section
              caption="General"
              timeSlots={sheet.timeSlots}
              items={sheet.items}
            />
          }
          {sheet.events.map(event =>
            <Section
              key={event.id}
              caption={event.caption}
              timeSlots={event.timeSlots}
              items={event.items}
            />
          )}
        </tbody>
      </Table>
    </>
  );
};

export default Signups;
