import styled from 'styled-components';

const Literal = styled.span`
  margin: 0 2px;
  padding: 0 3px;
  white-space: nowrap;
  border: 1px solid #e0dddb;
  background-color: #efebe8;
  border-radius: 3px;
`;

export default Literal;
