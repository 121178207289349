import React, {useState} from 'react';
import {post} from '../../../lib/services/client';
import styled from 'styled-components';

const Container = styled.div`
  margin-bottom: 15px;
`;

const Tip = styled.div`
  font-style: italic;
  font-size: 0.85em;
  color: #9b94b2;
`;

const tryParse = str => {
  try {
    return JSON.parse(str);
  } catch {}
};

const Setting = ({label, sheet, fieldName, action, targetProp = 'value', component: Component, tip, children}) => {
  const [value, setValue] = useState(sheet[fieldName] || undefined);
  const onChange = ({currentTarget}) => {
    const newValue = currentTarget[targetProp];
    const fieldHtml = `<strong>${label || fieldName.replace(/_/g, ' ')}</strong>`;
    return post(`/sheets/${action || `update_${fieldName}`}`, {id: sheet.id, value: newValue})
    .then(() => {
      Alert.pop_success(`Saved ${fieldHtml}`);
      setValue(newValue);
    })
    .catch(err => {
      const errors = tryParse(err.message);
      if (errors) {
        Alert.show_error(errors.join(' '));
      } else {
        Alert.show_error(`Uh oh, there was a problem trying to save ${fieldHtml}`);
      }
      setValue(value);
    })
  };
  return (
    <Container>
      <label>{label}</label>
      <Component
        value={value}
        onChange={onChange}
      />
      {tip && <Tip>{tip}</Tip>}
      {children}
    </Container>
  );
};

export default Setting;
