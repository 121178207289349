import React from 'react';
import {AlertProvider} from '../../lib/components/Alerter';
import CopyToClipboardMenuItem from '../../lib/components/CopyToClipboardMenuItem';

const CopySheetUrlMenuItemWrapper = ({publicUrl}) => {
  return (
    <AlertProvider>
      <CopyToClipboardMenuItem
        value={publicUrl}
        label="Copy to Clipboard"
        tip="Copy this sheet's public URL to your computer's clipboard"
      />
    </AlertProvider>
  );
}

export default CopySheetUrlMenuItemWrapper;
