import React from 'react';
import styled from 'styled-components';

const Container  = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 28px;
  outline: none;
  input {
    visibility: hidden;
  }
`;

const Slider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 28px;
  &:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }
  input:checked + & {
    background-color: #569aef;
  }
  input:checked + &:before {
    -webkit-transform: translateX(32px);
    -ms-transform: translateX(32px);
    transform: translateX(32px);
  }
`;

const DataLabel = styled.span`
  text-shadow: none;
  font-size: 0.8em;
`;

const YesLabel = styled(DataLabel)`
  color: #fff;
  margin-left: 9px;
  align-self: flex-start;
`;

const NoLabel = styled(DataLabel)`
  color: #666;
  margin-right: 11px;
  align-self: flex-end;
`;

const Toggle = ({value, onChange}) => {
  return (
    <Container>
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
      />
      <Slider>
        {value ? <YesLabel>YES</YesLabel> : <NoLabel>NO</NoLabel>}
      </Slider>
    </Container>
  );
};

export default Toggle;
