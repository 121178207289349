import React, {useState} from 'react';

const  ImportToSheet = () => {
  const [selectedFile, setSelectedFile] = useState();
  const [isSelected, setIsSelected] = useState();

  const fileChanged = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const upload = () => {
    const formData = new FormData();
    formData.append('File', selectedFile);
    return fetch('/sheets/import', {
      method: 'POST',
      body: formData
    });
  };

  return (
    <section>
      <h3>Import</h3>
      <div>
        <input type="file" name="file" onChange={fileChanged} />
        {isSelected ? <p>Filename: {selectedFile.name}</p> : <p>Select a file to show details</p>}
        <div>
          <button onClick={upload}>Submit</button>
        </div>
      </div>
    </section>
  );
};

export default ImportToSheet;
