import React from 'react';

const CalendarAlerts = ({sheet}) => {
  return (
    <section id="calendar-alerts">
      <h3>Calendar Subscriptions</h3>
      <div className="field" id="calendar-events-to-include-container" data-children-count="1">
        <label htmlFor="calendar-events-to-include">Events To Include</label>
        <select name="calendar-events-to-include" id="calendar-events-to-include"
          defaultValue="all"
        >
          <option value="all">All</option>
          <option value="with_signups">Only With Signups</option>
          <option value="with_confirmed_signups">Only With Cornfirmed Signups</option>
        </select>
        <div className="tip">Choose which events do you want to appear on your calendar.</div>
      </div>
      <label>Alerts</label>
      <div className="row-fluid">
        <div className="calendar-alert">
          <div className="calendar-alert">
            <div id="calendar-alert-first-container">
              <div className="iPhoneCheckContainer" style={{width: 91}} data-children-count="1">
                <input id="calendar-alert-first" type="checkbox" />
                <label className="iPhoneCheckLabelOff" style={{width: 86}}>
                  <span>NO</span>
                </label>
                <label className="iPhoneCheckLabelOn" style={{width: 0}}>
                  <span style={{marginLeft: -47}}>YES</span>
                </label>
                <div className="iPhoneCheckHandle" style={{width: 38}}>
                  <div className="iPhoneCheckHandleRight">
                    <div className="iPhoneCheckHandleCenter"></div>
                  </div>
                </div>
              </div>
            </div>
            <form id="update_calendar_alert_fields_first" noValidate="novalidate" className="simple_form sheet"
              action="/sheets/update_calendar_alert_fields_first" acceptCharset="UTF-8" data-remote="true" method="post">
              <div className="field hidden sheet_id"><input className="hidden" type="hidden" value="182" name="sheet[id]" id="sheet_id" /></div>
              <div className="field integer required sheet_calendar_alert_duration_first" data-children-count="1">
                <input className="numeric integer required" type="number" step="1" defaultValue="30" name="sheet[calendar_alert_duration_first]" id="sheet_calendar_alert_duration_first" />
              </div>
              <div className="field select required sheet_calendar_alert_unit_first" data-children-count="1">
                <select className="select required" name="sheet[calendar_alert_unit_first]" id="sheet_calendar_alert_unit_first"
                  defaultValue="M"
                >
                  <option value=""></option>
                  <option value="M">minutes</option>
                  <option value="H">hours</option>
                </select></div>
              <input type="submit" name="commit" value="Apply" className="btn-mini calendar-alert-submit-button"
                data-disable-with="Apply" />
            </form>
          </div>

          <div className="calendar-alert">
            <div id="calendar-alert-second-container">
              <div className="iPhoneCheckContainer" style={{width: 91}} data-children-count="1">
                <input id="calendar-alert-second" type="checkbox" />
                <label className="iPhoneCheckLabelOff" style={{width: 86}}>
                  <span>NO</span>
                </label><label className="iPhoneCheckLabelOn" style={{width: 0}}>
                  <span style={{marginLeft: -47}}>YES</span>
                </label>
                <div className="iPhoneCheckHandle" style={{width: 38}}>
                  <div className="iPhoneCheckHandleRight">
                    <div className="iPhoneCheckHandleCenter"></div>
                  </div>
                </div>
              </div>
            </div>
            <form id="update_calendar_alert_fields_second" noValidate="novalidate" className="simple_form sheet"
              action="/sheets/update_calendar_alert_fields_second" acceptCharset="UTF-8" data-remote="true"
              method="post">
              <div className="field hidden sheet_id">
                <input className="hidden" type="hidden" value="182" name="sheet[id]" id="sheet_id" />
              </div>
              <div className="field integer required sheet_calendar_alert_duration_second" data-children-count="1">
                <input className="numeric integer required" type="number" step="1" defaultValue="10"
                  name="sheet[calendar_alert_duration_second]" id="sheet_calendar_alert_duration_second" />
                </div>
              <div className="field select required sheet_calendar_alert_unit_second" data-children-count="1">
                <select className="select required" name="sheet[calendar_alert_unit_second]"
                  id="sheet_calendar_alert_unit_second"
                    defaultValue="M"
                  >
                  <option value=""></option>
                  <option value="M">minutes</option>
                  <option value="H">hours</option>
                </select></div>
              <input type="submit" name="commit" value="Apply" className="btn-mini calendar-alert-submit-button"
                data-disable-with="Apply" />
            </form>
          </div>

        </div>
      </div>
      <p className="tip">Set calendar alerts for events when you subscribe to the calendar feed for this sheet.</p>
      <label>Event Summary Format</label>
      <form id="update-calendar-event-summary-format" noValidate="novalidate" className="simple_form sheet"
        action="/sheets/update_calendar_event_summary_format" acceptCharset="UTF-8" data-remote="true" method="post">
        <div className="field hidden sheet_id">
          <input className="hidden" type="hidden" value="182" name="sheet[id]" id="sheet_id" />
        </div>
        <div className="field string required sheet_calendar_event_summary_format" data-children-count="1">
          <input className="string required" type="text" defaultValue="{title} - {attendees}"
            name="sheet[calendar_event_summary_format]" id="sheet_calendar_event_summary_format" />
          </div>
        <input type="submit" name="commit" value="Apply" className="btn-mini calendar-event-summary-format-submit-button" data-disable-with="Apply" />
      </form>
      <p className="tip">
        Each event in a calendar has headline, aka summary. Customize how the summary will appear in your calendar. Use the following tags to insert information from the sheet:
        <br />
        <span className="literal">{'{title}'}</span> - The title of the signup sheet.
        <br />
        <span className="literal">{'{attendees}'}</span>- The names of attendees up for the slot (separated by dashes of more than one).
        <br />
        <span className="literal">{'{items}'}</span> - The items for slot (if any, and separated by dashes of more than one).
      </p>
    </section>
  );
};

export default CalendarAlerts;
