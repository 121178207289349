import React from 'react';

const SignupButtons = ({sheet}) => {
  return (
    <section id="signup-buttons">
      <h3>Customize Signup Buttons</h3>
      <p className="tip">To customize the text on signup buttons, click a button, type the new text and press ENTER to
        save the change.</p>
      <div className="row-fluid">
        <div className="span4">
          <label>Sign Up Button</label>
          <div className="btn">
            <span data-placement="left" id="button-text-display" data-original-title="Click to edit"
              className="in-place-editable">Slot Me</span>
          </div>
        </div>
        <div className="span4">
          <label>Extra Sign Up Button</label>
          <div className="btn">
            <span data-placement="left" id="extra-button-text-display" data-original-title="Click to edit"
              className="in-place-editable">Slot Me As Extra</span>
          </div>
        </div>
      </div>
    </section>
  )
};

export default SignupButtons;
