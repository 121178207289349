import React from 'react';
import Toggle from '../shared/Toggle';
import Field from '../shared/Field';
import Literal from '../shared/Literal';
import styled from 'styled-components';

const Select = styled.select`
  height: 30px;
`;

const makeOptions = data => data.map(({value, label, disabled}) =>
  <option key={value} value={value} disabled={disabled}>{label}</option>
);

const Settings = ({sheet, timeZoneOptions, pastDatesOptions}) => {
  return (
    <section id="settings">
      <h3>Settings</h3>
      <Field
        label="Allow visitors to contact you?"
        sheet={sheet}
        fieldName="allow_contact_owner"
        targetProp="checked"
        component={props => <Toggle {...props} />}
        tip={<>
          <span>When set to </span> <Literal>YES</Literal> <span>visitors can send you a message via Slotted. We don't reveal your email or any personal information.</span>
        </>}
      />
      <Field
        label="Limit the number of signups per person to"
        sheet={sheet}
        fieldName="max_signups_per_person_per_sheet"
        component={({value, ...props}) => {
          return (
            <Select value={value || ''} {...props}>
              <option value="">unlimited</option>
              {Array.from(Array(25).keys(), i => i + 1).map(val =>
                <option key={val} value={val}>{val}</option>
              )}
            </Select>
          );
        }}
      />
      <Field
        label="Tabbed?"
        sheet={sheet}
        fieldName="tabbed"
        targetProp="checked"
        component={props => <Toggle {...props} />}
        tip={<>
          <span>When set to </span> <Literal>YES</Literal> <span>the sheet will display each date/location on a separate tab instead of on one scrolling page.</span>
        </>}
      />
      <Field
        label="Time Zone"
        sheet={sheet}
        fieldName="time_zone"
        component={props => {
          return (
            <Select {...props}>
              <option value="">- select -</option>
              {makeOptions(timeZoneOptions)}
            </Select>
          );
        }}
        tip="Specify a time zone so that people can more easily add events to their own personal calendar."
      />
      <Field
        label="Show Past Dates?"
        sheet={sheet}
        fieldName="past_dates"
        component={props => {
          return (
            <Select {...props}>
              {makeOptions(pastDatesOptions)}
            </Select>
          );
        }}
        tip="Control whether past dates are included in your sheet by default and whether the user is
        presented the option to show or hide."
      />
      <Field
        label="Notify of Signup Activity?"
        sheet={sheet}
        fieldName="notify_of_signup_activity"
        targetProp="checked"
        component={props => <Toggle {...props} />}
        tip="We'll send you an email whenever someone signs up or cancels a signup on this sheet."
      />
      <Field
        label="Auto Thank?"
        sheet={sheet}
        fieldName="auto_thank"
        targetProp="checked"
        component={props => <Toggle {...props} />}
        tip="We'll automatically send thank yous to people as they sign up (or you can send thank yous whenever you choose on the Follow Up tab)."
      />
      <Field
        label="Disable social signup?"
        sheet={sheet}
        fieldName="disable_social_signup"
        targetProp="checked"
        component={props => <Toggle {...props} />}
        tip={<>
          <span>When set to </span> <Literal>YES</Literal> <span>visitors will not be offered the option to signup using social accounts.</span>
        </>}
      />
    </section>
  );
};
export default Settings;
